import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import TextLockup from 'src/components/TextLockup'
import Button from 'src/components/Button'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { mq, globals, colors } from 'src/styles'

const Wrapper = styled(Section)`
  ${ mq.largeAndBelow } {
    ${ globals.verticalSpacing('padding-bottom') }
  }
`

const ColumnWrapper = styled.div`
  > div {
    direction: ltr;
  }
  h1, h2, h3 {
    max-width: 20em;
  }
  h4, h5 {
    max-width: 26em;
  }
  h6 {
    max-width: 16em;
  }
  p {
    max-width: 40em;
  }
  ${ ({ type }) => type === 'media' ? `
    // ${ mq.largeAndBelow } {
    //   order: 2;
    // }
  ` : `
    ${ mq.largeAndBelow } {
      background: ${ colors.lightGrey };
      padding: 20px 24px 24px 24px;
    }
  ` }
`

const TextWrapper = styled.div`
  p {
    color: var(--light-text-color);
    max-width: 26em;
    text-wrap: balance;
  }
`

const mediaSizes = {
  default: {
    normal: 100 / 28 * 11,
    large: 100 / 28 * 12,
    extraLarge: 100 / 28 * 13
  }
}

const Splitter = ({
  _key,
  className,
  theme,
  prevTheme,
  nextTheme,
  media,
  mediaPlacement = 'left',
  mediaWidth = 'extraLarge',
  width = 'default',
  eyebrow,
  text,
  actions,
  videoPopup,
  verticalAlignment = 'center',
  teachersHeadline,
  studentsHeadline,
  donateHeadline,
  isFirstSection,
  mediaCaption,
  layout = 'columns',
  id
}) => {
  const { allSanitySiteSettings } = useStaticQuery(
    graphql`
      query {
        allSanitySiteSettings {
          edges {
            node {
              ctas {
                studentsCta {
                  buttonLabel: ButtonLabel
                  secondaryButtonLabel: SecondaryButtonLabel
                  ctaText
                  asset {
                    gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 800)
                  }
                }
                teachersCta {
                  buttonLabel: ButtonLabel
                  secondaryButtonLabel: SecondaryButtonLabel
                  ctaText
                  asset {
                    gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 800)
                  }
                }
                donateCta {
                  buttonLabel: ButtonLabel
                  secondaryButtonLabel: SecondaryButtonLabel
                  ctaText
                  asset {
                    gatsbyImageData(layout: CONSTRAINED, width: 1200, height: 800)
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const sanitySiteSettings = allSanitySiteSettings?.edges[0]?.node

  const { studentsCta, teachersCta, donateCta } = sanitySiteSettings?.ctas

  if (!sanitySiteSettings?.ctas || !studentsCta?.asset?.gatsbyImageData || !teachersCta?.asset?.gatsbyImageData) {
    return false
  }

  // set responsive image sizes
  let sizes = '100vw'
  const imageSize = mediaSizes.default[mediaWidth]
  sizes = '(min-width: ' + mq.mediumBreakpoint + 'px) ' + imageSize + 'vw, 86vw'

  return (
    <Wrapper
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      id={id}
    >
      <Grid
        small={layout === 'columns' ? 'm [1] m' : '[1]'}
        large={layout === 'columns' ? 'm [1] g [1] g [1] m' : '[1]'}
        vAlign='top'
        rowGap={['var(--site-margins)', 'var(--site-margins)', 'calc(var(--site-margins) / 2)']}
      >
        <Grid small={layout === 'columns' ? '[1]' : 'm [1] m'}>
          <Grid
            small='[1]'
            large={layout === 'columns' ? '[1]' : '[13] g [9]'}
            vAlign={verticalAlignment}
            gridDirection='ltr'
          >
            <ColumnWrapper type="media">
              <ScrollEntrance>
                {teachersCta?.asset?.gatsbyImageData && (
                  <div style={{ position: 'relative' }}>
                    <Image
                      image={teachersCta.asset.gatsbyImageData}
                      alt={teachersCta?.ctaText || 'Teachers Image'}
                      sizes={sizes}
                      css={css`position: relative; z-index: 1;`}
                    />
                  </div>
                )}
              </ScrollEntrance>
            </ColumnWrapper>

            <ColumnWrapper>
              <div>
                <TextWrapper>
                  <TextLockup
                    entranceDelay={1}
                    text={teachersCta.ctaText}
                    headline={teachersHeadline || 'Teach With Us'}
                    headlineSize={layout === 'columns' ? 'h2' : 'h1'}
                    textSize={layout === 'columns' ? 'body' : 'bodyMedium'}
                    actions={[
                      <Button size={layout === 'columns' ? 'small' : 'medium'} to='https://careers.ascendlearning.org/' external target='_blank'>{teachersCta.buttonLabel || 'Enroll your child'}</Button>,
                      <Button size={layout === 'columns' ? 'small' : 'medium'} setTheme='textColor' title='Our approach' to='/approach'>{teachersCta.secondaryButtonLabel || 'Learn more'}</Button>
                    ]}
                    theme={theme}
                    showHr={false}
                    debug={true}
                  />
                </TextWrapper>
              </div>
            </ColumnWrapper>
          </Grid>
        </Grid>

        <Grid small={layout === 'columns' ? '[1]' : 'm [1] m'}>
          <Grid
            small='[1]'
            large={layout === 'columns' ? '[1]' : '[13] g [9]'}
            vAlign={verticalAlignment}
            gridDirection='rtl'
          >
            <ColumnWrapper type="media">
              <ScrollEntrance>
                {studentsCta?.asset?.gatsbyImageData && (
                  <div style={{ position: 'relative' }}>
                    <Image
                      image={studentsCta.asset.gatsbyImageData}
                      alt={studentsCta?.ctaText || 'Students Image'}
                      sizes={sizes}
                      css={css`position: relative; z-index: 1;`}
                    />
                  </div>
                )}
              </ScrollEntrance>
            </ColumnWrapper>

            <ColumnWrapper>
              <div>
                <TextWrapper>
                  <TextLockup
                    entranceDelay={1}
                    text={studentsCta.ctaText}
                    headline={studentsHeadline || 'Learn With Us'}
                    headlineSize={layout === 'columns' ? 'h2' : 'h1'}
                    textSize={layout === 'columns' ? 'body' : 'bodyMedium'}
                    actions={[
                      <Button size={layout === 'columns' ? 'small' : 'medium'} to='/enroll'>{studentsCta.buttonLabel || 'Enroll your child'}</Button>,
                      <Button size={layout === 'columns' ? 'small' : 'medium'} setTheme='textColor' title='About our schools' to='/schools'>{studentsCta.secondaryButtonLabel || 'Learn more'}</Button>
                    ]}
                    theme={theme}
                    showHr={false}
                  />
                </TextWrapper>
              </div>
            </ColumnWrapper>
          </Grid>
        </Grid>

        <Grid small={layout === 'columns' ? '[1]' : 'm [1] m'}>
          <Grid
            small='[1]'
            large={layout === 'columns' ? '[1]' : '[13] g [9]'}
            vAlign={verticalAlignment}
            gridDirection='ltr'
          >
            <ColumnWrapper type="media">
              <ScrollEntrance>
                {donateCta?.asset?.gatsbyImageData && (
                  <div style={{ position: 'relative' }}>
                    <Image
                      image={donateCta.asset.gatsbyImageData}
                      alt={donateCta?.ctaText || 'Teachers Image'}
                      sizes={sizes}
                      css={css`position: relative; z-index: 1;`}
                    />
                  </div>
                )}
              </ScrollEntrance>
            </ColumnWrapper>

            <ColumnWrapper>
              <div>
                <TextWrapper>
                  <TextLockup
                    entranceDelay={1}
                    text={donateCta.ctaText}
                    headline={donateHeadline || 'Donate Now'}
                    headlineSize={layout === 'columns' ? 'h2' : 'h1'}
                    textSize={layout === 'columns' ? 'body' : 'bodyMedium'}
                    actions={[
                      <Button size={layout === 'columns' ? 'small' : 'medium'} to='https://app.etapestry.com/onlineforms/AscendLearningInc/support.html' external target='_blank'>{donateCta.buttonLabel || 'Donate now'}</Button>,
                      <Button size={layout === 'columns' ? 'small' : 'medium'} setTheme='textColor' title='Support Ascend' to='/support-us'>{donateCta.secondaryButtonLabel || 'Learn more'}</Button>
                    ]}
                    theme={theme}
                    showHr={false}
                    debug={true}
                  />
                </TextWrapper>
              </div>
            </ColumnWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default Splitter
